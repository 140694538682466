$body-bg: #1D1D1B;
$body-color: #FAF6F5;

$primary: #215669;

$enable-shadows: false;
$input-btn-focus-width: 0;

// bootstrap source
@import "../node_modules/bootstrap/scss/bootstrap.scss";

body {
    background-image: url(/img/login_bg.png);
}

.login-wrapper {
    background-image: url(/img/login_img.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: auto;
    min-height: 100vh;
    width: 100%;

    .first {
        height: 234px;

        .right {
            form {
                width: 350px;

                .form-control {
                    background-color: transparent;
                    color: $body-color;
                    border-radius: 0;
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;

                    &:-webkit-autofill,
                    &:-webkit-autofill:hover,
                    &:-webkit-autofill:focus,
                    &:-webkit-autofill:active {
                        -webkit-box-shadow: 0 0 0 30px $body-bg inset !important;
                        box-shadow: 0 !important;
                    }

                    &:-webkit-autofill {
                        -webkit-text-fill-color: $body-color !important;
                    }
                }
            }
        }
    }

    .second {
        color: #91AEB7;
        height: 270px;

        .left {
            padding: 1rem;

            @include media-breakpoint-up(xl) {
                padding: 4rem;
                font-size: 1.25rem;
            }

            p {
                margin: 0;
            }
        }
    }

    .left, .right {
        flex-basis: 100px;
    }

    .middle {
        width: 500px;
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0 1rem 1rem 1rem;
        transition: all 0.3s ease;
        color: $light;

        a {
            color: $light;
        }
    }
}

// minimal width
@media (max-width: 1023.98px) {
    body, html {
        width: 1024px;
    }
}
